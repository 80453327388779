import {CANCEL} from 'redux-saga'

import {IS_LOCAL_ENV} from './constants/index.js'
import {stringifyURL} from './querystring.js'

export function Response(status, raw, headers) {
  headers = headers || {}
  this.status = status
  this.raw = raw
  this.headers = headers
  this.json = undefined

  var contentType = headers['content-type'] || headers['Content-Type']
  if (/application\/json/i.test(contentType)) {
    this.json = JSON.parse(raw)
  }
}

function XHR(method, url, data, headers) {
  this.method = method.toLowerCase()
  this.url = url
  this.data = data
  this.headers = headers || {}
  this.done = false
  this.xhr = null

  try {
    // For stacktrace in IE
    throw new Error()
  } catch (err) {
    this.error = err
  }
  this.error.name = this.ErrorName
  this.error.request = {
    url: this.url,
    method: this.method,
  }
}

function simpleSend(method, url, data, headers, timeout) {
  return new XHR(method, url, data, headers).send(timeout)
}

let stack = []
let listeners = []

export function formatErrorMessage(param, message, status) {
  if (param && message) {
    return `${param}: ${message}`
  }
  if (message) {
    return message
  }
  return `${status}`
}

export function getErrorMessage(response) {
  if (!response.json) {
    return response.raw
  }
  return response.json.error_message || response.json.message
}

export function getErrorParam(response) {
  if (!response.json) {
    return null
  }
  return response.json.param
}

Object.assign(XHR, {
  get: simpleSend.bind(undefined, 'get'),
  put: simpleSend.bind(undefined, 'put'),
  post: simpleSend.bind(undefined, 'post'),
  delete: simpleSend.bind(undefined, 'delete'),

  subscribe(callback) {
    listeners.push(callback)

    return callback
  },
  unsubscribe(handle) {
    listeners = listeners.filter((listener) => listener !== handle)
  },
  notify() {
    listeners.forEach((listener) => listener(stack))
  },
  addToStack(deferred, url, method) {
    stack.push({deferred, url, method})

    this.notify()
  },
  removeFromStack(deferred) {
    stack = stack.filter((item) => item.deferred !== deferred)

    this.notify()
  },
  getActiveRequests() {
    return stack
  },
})

Object.assign(XHR.prototype, {
  Response: Response,
  NEWLINE: '\u000d\u000a',
  timeout: 30000,
  ErrorName: 'XHRError',

  getError: function getError(response) {
    const param = getErrorParam(response)
    const errorMessage = getErrorMessage(response)

    this.error.message = formatErrorMessage(
      param,
      errorMessage,
      response.status,
    )
    this.error.error_message = errorMessage
    this.error.param = param
    this.error.response = response

    return this.error
  },

  getFullURL: function () {
    return this.method === 'get' ? stringifyURL(this.url, this.data) : this.url
  },
  getRequestBody: function () {
    return this.method !== 'get'
      ? this.data && JSON.stringify(this.data)
      : undefined
  },
  open: function () {
    if (!this.xhr) {
      this.xhr = new XMLHttpRequest()
    }
    this.xhr.open(this.method.toUpperCase(), this.getFullURL(), true)
  },
  addHeader: function (val, key) {
    this.xhr.setRequestHeader(key, val)
  },
  isSuccess: function (status) {
    return status && status >= 200 && status < 400
  },
  send: function () {
    const deferred = {}

    const promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve
      deferred.reject = reject

      this.open()

      this.xhr.timeout = 0
      this.xhr.onload = this.onload.bind(this, deferred)
      this.xhr.onerror = this.onerror.bind(this, deferred)
      this.xhr.ontimeout = this.ontimeout.bind(this, deferred)

      if (this.method !== 'get' && !this.headers['Content-Type']) {
        this.headers['Content-Type'] = 'application/json'
      }

      Object.keys(this.headers).forEach((key) =>
        this.addHeader(this.headers[key], key),
      )

      this.xhr.withCredentials = true
      this.xhr.send(this.getRequestBody())

      XHR.addToStack(deferred, this.getFullURL(), this.method)
    })

    promise.cancel = () => {
      this.xhr.abort()
      XHR.removeFromStack(deferred)
    }
    promise[CANCEL] = promise.cancel

    return promise
  },
  onload: function (deferred) {
    if (this.done) {
      return
    }
    this.done = true

    var status = this.xhr.status
    var body = this.xhr.responseText

    if (IS_LOCAL_ENV) {
      this.validateResponse(status, body)
    }

    try {
      const headers = this.parseResponseHeaders(
        this.xhr.getAllResponseHeaders(),
      )
      const response = new this.Response(status, body, headers)

      if (this.isSuccess(status)) {
        deferred.resolve(response)
      } else {
        this.error.response = response
        deferred.reject(this.getError(response))
      }
    } catch (err) {
      deferred.reject(err)
    }

    XHR.removeFromStack(deferred)
  },
  validateResponse: function (status, body) {
    if (typeof body !== 'string') {
      console.warn(
        'This response body is of type',
        body,
        '. It should probably be a string.',
      )
    }

    if (this.isSuccess(status) && /errors/i.test(body)) {
      console.warn(
        'The response contains errors but the status code is successful',
        status,
      )
    }
  },
  parseResponseHeaders: function (headerText) {
    var headers = {}

    ;(headerText || '').split(this.NEWLINE).forEach(function (headerPair) {
      var index = headerPair.indexOf(': ')
      if (index > 0) {
        var key = headerPair.substring(0, index)
        var val = headerPair.substring(index + 2)
        headers[key] = val
      }
    })

    return headers
  },
  onerror: function (deferred) {
    if (this.done) {
      return
    }
    this.done = true

    var response = new this.Response(-1, 'Error contacting the server')
    deferred.reject(this.getError(response))

    XHR.removeFromStack(deferred)
  },
  ontimeout: function (deferred) {
    if (this.done) {
      return
    }
    this.done = true

    var response = new this.Response(-1, 'Timeout contacting the server')
    deferred.reject(this.getError(response))

    XHR.removeFromStack(deferred)
  },
})

export default XHR
