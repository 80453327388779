import {CANCEL} from 'redux-saga'

import XHR from './XHR.js'
import logger from './logger.js'
import {stringify} from './querystring.js'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

class APIversonXHR extends XHR {}

APIversonXHR.prototype.ErrorName = 'APIversonError'

function send(method, url, data, headers = DEFAULT_HEADERS) {
  const fullURL = prefixURL(url).replace(/\/$/, '')
  const xhr = new APIversonXHR(method, fullURL, data, headers)

  const sendPromise = xhr.send()

  const catchPromise = sendPromise.catch((err) => {
    logger.error(err)

    if (err.response) {
      if (err.response.status === 401 || err.response.status === 503) {
        // Rate and label requests for amazon could return with 401 if account creds fail
        // We should fix this in the API but here I am
        if (!url.match(/amazon$/)) {
          window.location.href = '/login'
        }
      }
    }
    throw err
  })

  catchPromise.cancel = sendPromise.cancel
  // Allow redux-saga to automatically cancel requests
  catchPromise[CANCEL] = sendPromise.cancel

  return catchPromise
}

export function prefixURL(url) {
  return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_API_URL}/v3${url}`
}

export function formatV3APIURL(url, params) {
  return prefixURL(`${url}${params ? `?${stringify(params)}` : ''}`)
}

export default {
  send,
  get: send.bind(send, 'get'),
  put: send.bind(send, 'put'),
  post: send.bind(send, 'post'),
  delete: send.bind(send, 'delete'),
}
