import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isNil from 'lodash/isNil'
import lodashToNumber from 'lodash/toNumber'
import isString from 'lodash/isString'

export function isExactly(a, b) {
  return a === b
}

export function isPositive(value) {
  return Number(value) >= 0
}

export function isInteger(value) {
  return Number(value) === parseInt(Number(value), 10)
}

export function isPositiveInteger(value) {
  return isInteger(value) && isPositive(value)
}

export function isNumeric(value) {
  // lifted from http://stackoverflow.com/a/1830844
  return !isArray(value) && value - parseFloat(value) + 1 >= 0
}

export function isPositiveNumeric(value) {
  return isNumeric(value) && isPositive(value)
}

export function isNonZeroPositiveNumeric(value) {
  return isPositiveNumeric(value) && Number(value) > 0
}

export function isNonZeroPositiveInteger(value) {
  return isPositiveInteger(value) && Number(value) > 0
}

export function isPresent(value) {
  return (
    isNumber(value) || (isString(value) ? value.trim() !== '' : !isEmpty(value))
  )
}

export function isEmptyValue(value) {
  // unlike isEmpty, isEmptyValue returns false for numbers
  return value === null || value === undefined || value === ''
}

export function toNumber(value) {
  return isNil(value) ? value : lodashToNumber(value)
}

export function isAddressComplete(address, options) {
  if (!address) {
    return false
  }

  options = {
    name: true,
    company: false,
    name_or_company: false,
    phone: false,
    email: false,
    fax: false,
    street1: true,
    street2: false,
    city: true,
    state: true,
    zip: true,
    country: true,
    title: false,
    ...options,
  }

  return Object.keys(options).reduce((prev, key) => {
    if (!prev || !options[key]) {
      return prev
    }

    if (key === 'name_or_company') {
      return isPresent(address.name || address.company)
    }

    return isPresent(address[key])
  }, true)
}

export function areChildrenNotEmpty(children) {
  if (!children) {
    return false
  }

  let isNotEmpty = false
  React.Children.forEach(children, (child) => {
    if (!child || isNotEmpty) {
      return
    }

    if (child.type !== React.Fragment) {
      isNotEmpty = true

      return
    }

    if (!child.props) {
      return
    }

    if (areChildrenNotEmpty(child.props.children)) {
      isNotEmpty = true
    }
  })

  return isNotEmpty
}
