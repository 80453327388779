import 'whatwg-fetch'
import {CANCEL} from 'redux-saga'
import logger from './logger.js'
import {stringify} from './querystring.js'

import XHR, {getErrorParam, getErrorMessage, formatErrorMessage} from './XHR.js'

function APIXHR() {
  XHR.apply(this, arguments)

  this.url = this.fixupURL(this.url)
}

export function fixAPIURL(url) {
  if (!url.match(/\/$/) && !url.match(/^\/v3/)) {
    url = url + '/'
  }

  url = url.replace(/^\/api\//, '/')

  return formatAPIURL(url)
}

Object.assign(APIXHR.prototype, XHR.prototype, {
  timeout: 120000,
  ErrorName: 'APIError',
  fixupURL: fixAPIURL,
})

var api = {
  XHR: APIXHR,
  send: function (method, url, data) {
    var xhr = new this.XHR(method, url, data)

    const sendPromise = xhr.send()
    const catchPromise = sendPromise.catch((err) => {
      logger.error(err)

      if (err.response) {
        if (err.response.status === 401 || err.response.status === 503) {
          this.redirectToLogin()
        }
      }

      throw err
    })

    catchPromise.cancel = sendPromise.cancel
    // Allow redux-saga to automatically cancel requests
    catchPromise[CANCEL] = sendPromise.cancel

    return catchPromise
  },
  redirectToLogin: function () {
    window.location.href = '/login'
  },
}

export function formatAPIURL(url, params) {
  return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_API_URL}${url}${
    params ? `?${stringify(params)}` : ''
  }`
}

api.get = api.send.bind(api, 'get')
api.put = api.send.bind(api, 'put')
api.post = api.send.bind(api, 'post')
api.patch = api.send.bind(api, 'patch')
api.delete = api.send.bind(api, 'delete')

function getError(res, json) {
  const param = getErrorParam({json})
  const errorMessage = getErrorMessage({json})

  return {
    message: formatErrorMessage(param, errorMessage, res.status),
    error_message: errorMessage,
    param: param,
    response: res,
  }
}

export async function apiPost(url, data, options = {}) {
  const res = await fetch(formatAPIURL(url), {
    method: 'POST',
    credentials: 'include',
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const respBody = await res.json()

  if (res.status === 401 || res.status === 503) {
    window.location.href = '/login'
  } else if (res.status >= 400) {
    throw getError(res, respBody)
  } else if (!respBody) {
    throw getError(res, {error_message: 'No JSON payload returned'})
  }

  return respBody
}

export default api
